.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #3a54fb;
  font-size: 1.5625rem;
  font-weight: bold;
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.welcome-title {
  color: white;
  text-align: center;
  margin: auto;
}
.welcome-text {
  color: white;
  margin: 1.25rem;
}

.welcome-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 43.75rem) {
  .welcome-title {
    font-size: 5.5rem;
  }
  .welcome-text {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 43.75rem) {
  .welcome-title {
    font-size: 3.5rem;
  }
  .welcome-text {
    font-size: 1.5rem;
  }
}
